import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import A4 from "../Images/A4"

const PublicationListItem = ({ content }) => {
  const imageHeight = (29.7 / 21) * 100 // A4 = 21x29.7

  return (
    <div className="publication-list-item">
      <div className="flex flex-wrap items-center">
        <div className="relative w-full md:w-1/5">
          <div
            aria-hidden={true}
            className="image-placeholder"
            style={{ paddingBottom: `${imageHeight}%` }}
          ></div>
          <figure className="image absolute inset-0 overflow-hidden">
            {content.relationships.image ? (
              <Img
                fluid={
                  content.relationships.image.localFile.childImageSharp.fluid
                }
                alt={content.title}
              />
            ) : (
              <A4 alt={content.title} className="absolute inset-0" />
            )}
          </figure>
        </div>
        <div className="w-full md:w-4/5 md:px-8 py-8">
          <h3 className="title h3 text-secondary mb-0">
            <span>{content.title}</span>
          </h3>
          {content.date_text && (
            <div className="uppercase font-semibold text-secondary mb-8">
              {content.date_text}
            </div>
          )}
          <div className="buttons flex flex-col md:flex-row flex-wrap lg:items-center">
            <a
              href={content.relationships.file.localFile.publicURL}
              title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
              target="_blank"
              rel="noreferrer"
              className="button button-primary flex md:inline-flex group mb-4 md:mb-0 md:mr-4"
            >
              <span className="icon mr-2">
                <i className="fas fa-file">
                  <span className="hidden">Visualiser</span>
                </i>
              </span>
              <span>Visualiser</span>
            </a>
            <a
              href={content.relationships.file.localFile.publicURL}
              title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
              target="_blank"
              rel="noreferrer"
              className="button button-primary flex md:inline-flex group"
              download
            >
              <span className="icon mr-2">
                <i className="fas fa-file-download">
                  <span className="hidden">Télécharger</span>
                </i>
              </span>
              <span>Télécharger</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

PublicationListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default PublicationListItem
