import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const A4 = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "placeholder.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 210
            maxHeight: 297
            cropFocus: CENTER
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.file.childImageSharp.fluid} {...props} />
}

export default A4
